@tailwind base;
@tailwind components;

@font-face {
    font-family: 'Helvetica Neue';
    src: url('./fonts/helvetica-neue/HelveticaNeueBoldCondensed.eot');
    src: local('/fonts/Helvetica/Helvetica Neue Condensed Bold'), local('/fonts/Helvetica/HelveticaNeueBoldCondensed'),
    url('./fonts/helvetica-neue/HelveticaNeueBoldCondensed.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueBoldCondensed.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueBoldCondensed.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueBoldCondensed.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('./fonts/helvetica-neue/HelveticaNeueBlackCondensed.eot');
    src: local('/fonts/Helvetica/Helvetica Neue Condensed Black'), local('/fonts/Helvetica/HelveticaNeueBlackCondensed'),
    url('./fonts/helvetica-neue/HelveticaNeueBlackCondensed.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueBlackCondensed.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueBlackCondensed.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueBlackCondensed.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('./fonts/helvetica-neue/HelveticaNeueCyr-Bold.eot');
    src: local('/fonts/Helvetica/HelveticaNeueCyr-Bold'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Bold.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Bold.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('./fonts/helvetica-neue/HelveticaNeueCyr-Light.eot');
    src: local('/fonts/Helvetica/HelveticaNeueCyr-Light'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Light.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Light.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('./fonts/helvetica-neue/HelveticaNeueCyr-Medium.eot');
    src: local('/fonts/Helvetica/HelveticaNeueCyr-Medium'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Medium.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Medium.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('./fonts/helvetica-neue/HelveticaNeueCyr-MediumItalic.eot');
    src: local('/fonts/Helvetica/HelveticaNeueCyr-MediumItalic'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-MediumItalic.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-MediumItalic.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('./fonts/helvetica-neue/HelveticaNeueCyr-LightItalic.eot');
    src: local('/fonts/Helvetica/HelveticaNeueCyr-LightItalic'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-LightItalic.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-LightItalic.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('./fonts/helvetica-neue/HelveticaNeueCyr-HeavyItalic.eot');
    src: local('/fonts/Helvetica/HelveticaNeueCyr-HeavyItalic'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-HeavyItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-HeavyItalic.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-HeavyItalic.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('./fonts/helvetica-neue/HelveticaNeueCyr-Black.eot');
    src: local('/fonts/Helvetica/HelveticaNeueCyr-Black'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Black.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Black.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Black.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('./fonts/helvetica-neue/HelveticaNeueCyr-Heavy.eot');
    src: local('/fonts/Helvetica/HelveticaNeueCyr-Heavy'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Heavy.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Heavy.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Heavy.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('./fonts/helvetica-neue/HelveticaNeueCyr-Italic.eot');
    src: local('/fonts/Helvetica/HelveticaNeueCyr-Italic'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Italic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Italic.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Italic.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Italic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('./fonts/helvetica-neue/HelveticaNeueCyr-Roman.eot');
    src: local('/fonts/Helvetica/HelveticaNeueCyr-Roman'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Roman.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Roman.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Roman.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Roman.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('./fonts/helvetica-neue/HelveticaNeueCyr-BlackItalic.eot');
    src: local('/fonts/Helvetica/HelveticaNeueCyr-BlackItalic'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-BlackItalic.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-BlackItalic.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('./fonts/helvetica-neue/HelveticaNeueCyr-UltraLightItalic.eot');
    src: local('/fonts/Helvetica/HelveticaNeueCyr-UltraLightItalic'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-UltraLightItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-UltraLightItalic.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-UltraLightItalic.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-UltraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('./fonts/helvetica-neue/HelveticaNeueCyr-BoldItalic.eot');
    src: local('/fonts/Helvetica/HelveticaNeueCyr-BoldItalic'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-BoldItalic.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-BoldItalic.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('./fonts/helvetica-neue/HelveticaNeueCyr-ThinItalic.eot');
    src: local('/fonts/Helvetica/HelveticaNeueCyr-ThinItalic'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-ThinItalic.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-ThinItalic.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('./fonts/helvetica-neue/HelveticaNeueCyr-UltraLight.eot');
    src: local('/fonts/Helvetica/HelveticaNeueCyr-UltraLight'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-UltraLight.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-UltraLight.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-UltraLight.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('./fonts/helvetica-neue/HelveticaNeueCyr-Thin.eot');
    src: local('/fonts/Helvetica/HelveticaNeueCyr-Thin'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Thin.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Thin.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Thin.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}



@tailwind utilities;

.scroll-infinity {
    transition: all .3s;
}

.triangle:after {
    content: '';
    top: -8px;
    right: 29.5%;
    width: 0;
    height: 0;
    position: absolute;
    border: 0 solid transparent;
    border-right-width: 9px;
    border-left-width: 9px;
    border-bottom: 8px solid #D9D4DD;
    z-index: 1;
}

.box-for-shadow {
    position: relative;
}

@media (max-width: 560px) {
    .box-for-shadow:before, .box-for-shadow:after {
        display: none;
    }
}

.box-for-shadow:after, .box-for-shadow:before {
    content: '';
    position: absolute;
    top: 0;
    width: 180px;
    height: 100%;
    z-index: 1;
}

.box-for-shadow:before {
    background: linear-gradient(90deg, rgba(255,255,255, 0.8) 0%, rgba(30, 27, 33, 0) 100%);
    left: 0;
}

.box-for-shadow:after {
    right: 0;
    background: linear-gradient(-90deg, rgba(255,255,255, 0.8) 0%, rgba(30, 27, 33, 0) 100%);
}

.custom-scroll[data-scrollbar]:not(.block) {
    display: none;
}

* {
    letter-spacing: -0.045em;
}

@media(max-width: 48rem) {
    .box-for-shadow:after, .box-for-shadow:before {
        display: none;
    }
}
